import Footer from "./footer"
import logo from '../logo-dark.png'
import { Link } from 'react-router-dom';

const cards = [
  {
    url: 'performity-login',
    name: 'Performity',
    title: 'Performance dashboard for Real Estate Agents',
    role: 'Desktop/Mobile',
    imageUrl:
      '/admin.png',
  },
  {
    url: 'give-on-the-go',
    name: 'Give On The Go',
    title: 'Mobile application for public donation collectors',
    role: 'Mobile',
    imageUrl:
      '/gotg.png',
  },
  {
    url: 'paw-floor',
    name: 'PawFloor',
    title: '2-sided marketplace where dog-owners can find dog-walkers who advertise on the site',
    role: 'Desktop/Mobile',
    imageUrl:
      '/web.png',
  }
]

const cards2 = [
  {
    url: 'landing',
    name: 'Landing Page',
    title: 'Homepage for your application - where your users land',
    role: 'Desktop/Mobile',
    imageUrl:
      '/landing.png',
  },
  {
    url: 'login',
    name: 'Application Login',
    title: 'Authenticate users with our pre-built authentication system & login page',
    role: 'Desktop/Mobile',
    imageUrl:
      '/login.png',
  },
  {
    url: 'register',
    name: 'Application Registrations',
    title: 'Users can sign up to your application using a registration page',
    role: 'Desktop/Mobile',
    imageUrl:
      'register.png',
  },
]

export default function DemoHome() {
  return (
    <>
      <div className="min-h-full flex flex-col">
        <div className="md:flex md:items-center md:justify-between md:space-x-5 p-5 bg-green-600 shadow-lg">
          <div className="flex items-start space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                <img
                  className="h-16 w-16 "
                  src={logo}
                  alt=""
                />
              </div>
            </div>
            <div className="pt-1.5">
              <h1 className="text-2xl font-bold text-gray-100">Verticode Demo</h1>
              <p className="text-sm font-medium text-gray-50">
                Verticode Application & Component Examples
              </p>
            </div>
          </div>
          <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
            <button
              onClick={() => window.location.replace('https://www.verticode.co.uk')}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            >
              Back To Website
            </button>
            <a
              href="https://calendly.com/verticode/30min"
              target="_blank"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            >
              Book a Meeting
            </a>
          </div>
        </div>
        <main className="mb-auto bg-gray-50">
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">

            <div className="border-b border-gray-200 pb-5">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Example Applications</h3>
              <p className="mt-2 max-w-4xl text-sm text-gray-500">
                Examples of Verticode MVP applications 
              </p>
            </div>
            <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 mt-5">
              {cards.map((card) => (

                <li
                  key={card.email}
                  className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
                >
                  <Link to={card.url}>
                    <div className="flex flex-1 flex-col p-8">
                      <img className="rounded-sm " src={card.imageUrl} alt="" />
                      <h3 className="mt-6 text-sm font-medium text-gray-900">{card.name}</h3>
                      <dl className="mt-1 flex flex-grow flex-col justify-between">
                        <dd className="text-sm text-gray-500">{card.title}</dd>
                        <dd className="mt-3">
                          <span className="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                            {card.role}
                          </span>
                        </dd>
                      </dl>
                    </div>
                  </Link>

                </li>
              ))}
            </ul>
            <div className="border-b border-gray-200 pb-5 mt-5">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Example Components</h3>
              <p className="mt-2 max-w-4xl text-sm text-gray-500">
                These are examples of common pre-built Verticode components. They are all fully functioning and ready to plug into your application. Unlike a normal development agency you don't need to pay to have these standard components re-built!
              </p>
            </div>
            <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 mt-5">
              {cards2.map((card) => (

                <li
                  key={card.email}
                  className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
                >
                  <Link to={card.url}>
                    <div className="flex flex-1 flex-col p-8">
                      <img className="rounded-sm" src={card.imageUrl} alt="" />
                      <h3 className="mt-6 text-sm font-medium text-gray-900">{card.name}</h3>
                      <dl className="mt-1 flex flex-grow flex-col justify-between">
                        <dd className="text-sm text-gray-500">{card.title}</dd>
                        <dd className="mt-3">
                          <span className="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                            {card.role}
                          </span>
                        </dd>
                      </dl>
                    </div>
                  </Link>

                </li>
              ))}
            </ul>
          </div>
        </main>
        <Footer></Footer>

      </div>


    </>
  )
}