import Header from './header';
import Footer from './footer';
import { Outlet } from "react-router-dom";
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3CenterLeftIcon,
  BellIcon,
  CalendarDaysIcon,
  ChartBarIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  ArrowUpCircleIcon,
  BanknotesIcon,
  BuildingOfficeIcon,
  ChartPieIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CubeIcon,
  FireIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid'

const cards = [
  { name: 'Total No. Sales', href: '#', icon: CurrencyDollarIcon, amount: '23' },
  { name: 'Avg % Above Asking', href: '#', icon: ArrowUpCircleIcon, amount: '4%' },
  { name: 'Avg Time To Sale', href: '#', icon: ClockIcon, amount: '29 Days' },
]

const cards2 = [
  { name: 'Performity Score', href: '#', icon: ChartBarIcon, amount: '65' },
  { name: 'Top Estates Ranking', href: '#', icon: FireIcon, amount: '2' },
  { name: 'Next Month\'s target', href: '#', icon: CalendarDaysIcon, amount: '70' },
]
const transactions = [
  {
    id: 1,
    property: 'Flat 6, 105 Howell Road',
    sale: '£220k',
    abvAsking: '£15k',
    days: '36',
    score: '67/100',
    status: 'processing',
    date: new Date(new Date().getYear(), new Date().getMonth(), new Date().getDate() - 4).toDateString(),
  },
  {
    id: 1,
    property: '36 Smith Street',
    sale: '£410k',
    abvAsking: '£40k',
    days: '12',
    score: '92/100',
    status: 'success',
    date: new Date(new Date().getYear(), new Date().getMonth(), new Date().getDate() - 9).toDateString(),
  },
  {
    id: 1,
    property: '12 Ornoval Terrace',
    sale: '£210k',
    abvAsking: '-£5k',
    days: '67',
    score: '14/100',
    status: 'failed',
    date: new Date(new Date().getYear(), new Date().getMonth(), new Date().getDate() - 26).toDateString(),
  },
  {
    id: 1,
    property: '133 Furnival Road',
    sale: '£510k',
    abvAsking: '£12k',
    days: '23',
    score: '77/100',
    status: 'success',
    date: new Date(new Date().getYear(), new Date().getMonth(), new Date().getDate() - 34).toDateString(),
  },
]

const transactions2 = [
  {
    id: 1,
    property: 'Flat 12, 334 Green Road',
    sale: '£420k',
    abvAsking: '£15k',
    days: '...',
    score: '.../100',
    status: 'processing',
    date: new Date(new Date().getYear(), new Date().getMonth(), new Date().getDate() - 3).toDateString(),
  },
  {
    id: 1,
    property: '21 Yarn Street',
    sale: '£110k',
    abvAsking: '£5k',
    days: '...',
    score: '.../100',
    status: 'processing',
    date: new Date(new Date().getYear(), new Date().getMonth(), new Date().getDate() - 7).toDateString(),
  },
  {
    id: 1,
    property: '6 Perspecs Terrace',
    sale: '£710k',
    abvAsking: '-£25k',
    days: '...',
    score: '.../100',
    status: 'processing',
    date: new Date(new Date().getYear(), new Date().getMonth(), new Date().getDate() - 14).toDateString(),
  },
  {
    id: 1,
    property: '121 Arndal Road',
    sale: '£210k',
    abvAsking: '£14k',
    days: '...',
    score: '.../100',
    status: 'processing',
    date: new Date(new Date().getYear(), new Date().getMonth(), new Date().getDate() - 21).toDateString(),
  },
]
const statusStyles = {
  success: 'bg-green-100 text-green-800',
  processing: 'bg-yellow-100 text-yellow-800',
  failed: 'bg-gray-100 text-gray-800',
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function PerformitySales() {
  return (
    <>


      <div className="mt-8">
        <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
          <h2 className="text-lg font-medium leading-6 text-gray-900">Overview</h2>
          <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {/* Card */}
            {cards.map((card) => (
              <div key={card.name} className="overflow-hidden rounded-lg bg-white shadow">
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <card.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        <dt className="truncate text-sm font-medium text-gray-500">{card.name}</dt>
                        <dd>
                          <div className="text-lg font-medium text-gray-900">{card.amount}</div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-5 py-3">
                  <div className="text-sm">
                    <a href={card.href} className="font-medium text-emerald-700 hover:text-emerald-900">
                      View all
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
          Completed Sales
        </h2>

        <div className="block">
          <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <div className="mt-2 flex flex-col">
              <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th
                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                        scope="col"
                      >
                        Property
                      </th>
                      <th
                        className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                        scope="col"
                      >
                        Sale Price
                      </th>
                      <th
                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                        scope="col"
                      >
                        Above Asking
                      </th>
                      <th
                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                        scope="col"
                      >
                        Days to sale
                      </th>
                      <th
                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                        scope="col"
                      >
                        Performity Score (/100)
                      </th>
                      <th
                        className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                        scope="col"
                      >
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {transactions.map((transaction) => (
                      <tr key={transaction.id} className="bg-white">
                        <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                          <div className="flex">
                            <a href={transaction.href} className="group inline-flex space-x-2 truncate text-sm">
                              <BuildingOfficeIcon
                                className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                              <p className="truncate text-gray-500 group-hover:text-gray-900">
                                {transaction.property}
                              </p>
                            </a>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                          <span className="font-medium text-gray-900">{transaction.sale}</span>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                          <span className="font-medium text-gray-900">{transaction.abvAsking}</span>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                          <span className="font-medium text-gray-900">{transaction.days}</span>
                        </td>
                        <td className="hidden whitespace-nowrap px-6 py-4 text-sm text-gray-500 md:block">
                          <span
                            className={classNames(
                              statusStyles[transaction.status],
                              'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                            )}
                          >
                            {transaction.score}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                        {transaction.date}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
          Pending Sales
        </h2>

        <div className="block">
          <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <div className="mt-2 flex flex-col">
              <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th
                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                        scope="col"
                      >
                        Property
                      </th>
                      <th
                        className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                        scope="col"
                      >
                        Sale Price
                      </th>
                      <th
                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                        scope="col"
                      >
                        Above Asking
                      </th>
                      <th
                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                        scope="col"
                      >
                        Days to sale
                      </th>
                      <th
                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                        scope="col"
                      >
                        Performity Score (/100)
                      </th>
                      <th
                        className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                        scope="col"
                      >
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {transactions2.map((transaction) => (
                      <tr key={transaction.id} className="bg-white">
                        <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                          <div className="flex">
                            <a href={transaction.href} className="group inline-flex space-x-2 truncate text-sm">
                              <BuildingOfficeIcon
                                className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                              <p className="truncate text-gray-500 group-hover:text-gray-900">
                                {transaction.property}
                              </p>
                            </a>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                          <span className="font-medium text-gray-900">{transaction.sale}</span>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                          <span className="font-medium text-gray-900">{transaction.abvAsking}</span>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                          <span className="font-medium text-gray-900">{transaction.days}</span>
                        </td>
                        <td className="hidden whitespace-nowrap px-6 py-4 text-sm text-gray-500 md:block">
                          <span
                            className={classNames(
                              statusStyles[transaction.status],
                              'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                            )}
                          >
                            {transaction.score}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                        {transaction.date}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
