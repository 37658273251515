import Header from './header';
import Footer from './footer';
import { Outlet } from "react-router-dom";
import logo from '../logo-dark.png'
import { Link } from 'react-router-dom'

function PfLayout() {

    return (
        <div className="min-h-full flex flex-col bg-gray-100">
            <Header></Header>

            <main className="mb-auto">
                <div className="mx-auto max-w-7xl">
                    <Outlet />
                </div>
            </main>
            <Footer></Footer>

        </div>
    )
}

export default PfLayout;