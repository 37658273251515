import { ArrowRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

const data = [{ name: 'British Red Cross', details: 'Together, We Are The World’s Emergency Responders' }, { name: 'Charity Water', details: 'Providing clean water to the world' }, { name: 'Dogs Trust', details: 'Raising for dogs across the UK' }]

export default function GotgHome() {

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState(false);

  return (
    <>
      <div className="w-96 h-full pt-10">
        <div className="relative">
          {page > 0 && <ChevronLeftIcon className="text-fuchsia-600 absolute top-[-25px] left-0 w-12 h-8" onClick={() => setPage(page - 1)}></ChevronLeftIcon>}
          </div>
        
        {page === 0 && <>
          <button
            type="button"
            className="mr-5 mt-5 float-right inline-flex justify-center rounded-md border border-transparent bg-fuchsia-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-fuchsia-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"

          >
            Add Fundraiser
          </button>
          <div className="h-14 text-2xl font-bold p-6 text-gray-800">Fundraisers</div>
          <p className="text-sm text-gray-600 p-6">These are all your active fundraisers. Select a campaign below to view your campaign QR code.</p>
          <div className="grid grid-cols-1">
            {data.map(x => <div onClick={() => setPage(1)} className="rounded-xl cursor-pointer bg-white shadow h-26 mx-6 mt-4 p-3 flex flex-row">
              <div className="flex-1">
                <div className="text-xl font-bold text-fuchsia-700">{x.name}</div>
                <div className="text-md text-gray-500">{x.details}</div>
              </div>
              <div className="h-8 w-8 text-gray-300 hover:text-gray-500"><ArrowRightIcon className="text-sm"></ArrowRightIcon></div>
            </div>)}
          </div>

        </>}
        {page === 1 && <>
          <div className="h-14 text-2xl font-bold text-center p-6 text-gray-800">British Red Cross</div>
          <p className="px-6 mt-5 text-md text-center text-gray-600">Scan the QR code to donate to the British Red Cross</p>
          <div className="m-6 bg-white rounded-xl mb-10 text-center"><img className="w-full" src="/frame.png"></img></div>
          <div className="flex">
            <button
              onClick={() => setPage(2)}
              type="button"
              className="mx-auto relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-fuchsia-500 focus:outline-none focus:ring-1 focus:ring-fuchsia-500"
            >
              Follow QR Code
            </button>

          </div>

        </>}
        {page === 2 && <>
          <div className="h-14 text-2xl font-bold p-6 text-gray-800 text-center">Thank you for donating to the British Red Cross.</div>
          <div className="m-6 bg-white rounded-xl p-5 text-center shadow-md mt-20">
            <p className="text-gray-800 mb-6 font-medium">Your donation helps us help people in crisis.</p>
            <span className="isolate inline-flex rounded-md shadow-sm mb-6">
              <button
                onClick={() => setSelected(true)}
                type="button"
                className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-fuchsia-500 focus:outline-none focus:ring-1 focus:ring-fuchsia-500"
              >
                £10
              </button>
              <button
                onClick={() => setSelected(true)}
                type="button"
                className="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-fuchsia-500 focus:outline-none focus:ring-1 focus:ring-fuchsia-500"
              >
                £20
              </button>
              <button
                onClick={() => setSelected(true)}
                type="button"
                className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-fuchsia-500 focus:outline-none focus:ring-1 focus:ring-fuchsia-500"
              >
                £50
              </button>
            </span>
            <br />
            {selected && <a href="https://buy.stripe.com/test_cN2g1M9jp5wd2vmdQQ" target="_blank"
              className="cursor-pointer text-center inline-flex items-center rounded-md border border-transparent bg-fuchsia-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-fuchsia-700 focus:outline-none focus:ring-2 focus:ring-fuchsia-500 focus:ring-offset-2"
            >
              Pay
            </a>}
            {!selected && <div
              className="text-center inline-flex items-center rounded-md border border-transparent bg-gray-200 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-fuchsia-500 focus:ring-offset-2"
            >
              Pay
            </div>}
          </div>
          <div className="m-6 bg-white rounded-xl p-5 pt-1 shadow-md">
            <Links open={() => setOpen(true)}></Links>
          </div>
        </>}

      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all max-w-xs sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Africa
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          The British Red Cross is supporting struggling people all over Africa. There is an ongoing food crisis effecting countries all over the continent.
                        </p>
                        <p className="text-sm text-gray-500 mt-3">
                          146 Million are facing catastrophe, you can help us help them by donating today.
                        </p>
                        <p className="text-sm text-gray-500 mt-3 underline cursor-pointer">
                          Find Out More
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-fuchsia-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-fuchsia-700 focus:outline-none focus:ring-2 focus:ring-fuchsia-500 focus:ring-offset-2 sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

const people = [
  {
    name: 'UK',
    handle: 'Find out about our work across the UK'
  },
  {
    name: 'Africa',
    handle: 'Find out about our work in Africa'
  },
  {
    name: 'Europe',
    handle: 'Find out about our work in Europe'
  },
]
function Links(props) {
  return (
    <div>
      <p className="text-gray-800 mb-6 font-medium text-center mt-5">Find out more about our work around the globe.</p>
      <div className="mt-6 flow-root">
        <ul role="list" className="-my-5 divide-y divide-gray-200">
          {people.map((person) => (
            <li key={person.handle} className="py-4">
              <div className="flex items-center space-x-4">
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-medium text-gray-900">{person.name}</p>
                  <p className="truncate text-sm text-gray-500">{person.handle}</p>
                </div>
                <div>
                  <a
                    onClick={() => props.open()}
                    href="#"
                    className="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50"
                  >
                    More
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-6">
        <a
          href="#"
          className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
        >
          View More
        </a>
      </div>
    </div>
  )
}