import logo from '../logo3.png'
import { Link } from 'react-router-dom'

export default function Login3() {
  return (
    <div className="min-h-full flex flex-col">
      <div className="mt-20 h-auto flex-1 bg-gray-50 flex flex min-h-full flex-col justify-center py-12 px-6">
        <div className="">
          <img
            className="mx-auto h-12 w-auto"
            src={logo}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Sign in to your account</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <a href="#" className="font-medium text-fuchsia-600 hover:text-fuchsia-500">
              register for a new account
            </a>
          </p>
        </div>

        <div className="mt-8">
          <div className="py-8 px-4">
            <form className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-fuchsia-500 focus:outline-none focus:ring-fuchsia-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-fuchsia-500 focus:outline-none focus:ring-fuchsia-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">


                <div className="text-sm">
                  <a href="#" className="font-medium text-fuchsia-600 hover:text-fuchsia-500">
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div>
                <Link
                  to='/give-on-the-go/give'
                  className="flex w-full justify-center rounded-md border border-transparent bg-fuchsia-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-fuchsia-700 focus:outline-none focus:ring-2 focus:ring-fuchsia-500 focus:ring-offset-2"
                >
                  Sign in
                </Link>
              </div>
            </form>

          </div>
        </div>
      </div>


    </div>
  )
}
