import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '../logo.png'
import { CheckIcon } from '@heroicons/react/24/outline'
import Footer from './footer'
import { Link } from 'react-router-dom'

const navigation = [
  { name: 'Home', href: '#' },
  { name: 'Custom Page?', href: '#' },
  { name: 'Custom Feature?', href: '#' },
]

const hobbyFeatures = ['Great Product Feature 1', 'Great Product Feature 2', 'Great Product Feature 3']
const scaleFeatures = ['Ultimate Product Feature 1', 'Ultimate Product Feature 2', 'Ultimate Product Feature 3']
const growthFeatures = [
  'Great Product Feature 1',
  'Great Product Feature 2',
  'Great Product Feature 3',
  'Fantastic Product Feature 1',
  'Fantastic Product Feature 2',
]

const faqs = [
  {
    question: "Lorem ipsum dolor sit amet?",
    answer:
      "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
  },
  {
    question: "Lorem ipsum dolor sit amet?",
    answer:
      "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
  },
  {
    question: "Lorem ipsum dolor sit amet?",
    answer:
      "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
  },
]


export default function LandingPage() {
  return (
    <>
      <div className="relative overflow-hidden bg-white">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
            <svg
              className="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-white lg:block"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <Popover>
              <div className="relative px-4 pt-6 sm:px-6 lg:px-8">
                <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
                  <div className="flex flex-shrink-0 flex-grow items-center lg:flex-grow-0">
                    <div className="flex w-full items-center justify-between md:w-auto">
                      <a href="#">
                        <img
                          alt="Your Company"
                          className="h-8 w-auto sm:h-10"
                          src={logo}
                        />
                      </a>
                      <div className="-mr-2 flex items-center md:hidden">
                        <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Open main menu</span>
                          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:ml-10 md:block md:space-x-8 md:pr-4">
                    {navigation.map((item) => (
                      <a key={item.name} href={item.href} className="font-medium text-gray-500 hover:text-gray-900">
                        {item.name}
                      </a>
                    ))}
                    <a href="#" className="font-medium text-fuchsia-600 hover:text-fuchsia-500">
                      Log in
                    </a>
                  </div>
                </nav>
              </div>

              <Transition
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
                >
                  <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                    <div className="flex items-center justify-between px-5 pt-4">
                      <div>
                        <img
                          className="h-8 w-auto"
                          src={logo}
                          alt=""
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-fuchsia-500">
                          <span className="sr-only">Close main menu</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="space-y-1 px-2 pt-2 pb-3">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <a
                      href="#"
                      className="block w-full bg-gray-50 px-5 py-3 text-center font-medium text-fuchsia-600 hover:bg-gray-100"
                    >
                      Log in
                    </a>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>

            <main className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block xl:inline">This Could Be Your</span>{' '}
                  <span className="block text-fuchsia-600 xl:inline">Landing Page</span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                  Your landing page is the first thing your users see. We will customise a landing page for your brand, and can include custom features and components to serve any purpose.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <a
                      href="#"
                      className="flex w-full items-center justify-center rounded-md border border-transparent bg-fuchsia-600 px-8 py-3 text-base font-medium text-white hover:bg-fuchsia-700 md:py-4 md:px-10 md:text-lg"
                    >
                      Start Now!
                    </a>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <a
                      href="#"
                      className="flex w-full items-center justify-center rounded-md border border-transparent bg-fuchsia-100 px-8 py-3 text-base font-medium text-fuchsia-700 hover:bg-fuchsia-200 md:py-4 md:px-10 md:text-lg"
                    >
                      Login!
                    </a>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
            src="https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            alt=""
          />
        </div>
      </div>

      <div className="bg-white">

        <div className="mt-16 bg-white pb-12 lg:mt-20 lg:pb-20">
          <div className="relative z-0">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="h-96 rounded-lg border-4 border-dashed border-gray-200 flex">

                <div class="m-auto">
                  <h2 className="text-3xl select-none font-semibold leading-6 text-gray-300 text-center">Custom Feature?</h2>
                  <p className="text-1xl select-none font-semibold leading-6 text-gray-300 text-center">We can custom build you a feature and plug it into the site here</p>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>


      <div className="bg-gray-900">
        <div className="px-4 pt-12 sm:px-6 lg:px-8 lg:pt-20">
          <div className="text-center">
            <h2 className="text-xl font-semibold leading-6 text-gray-300">Pricing Example</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl lg:text-5xl">
              Subscription Based Pricing Example
            </p>
            <p className="mx-auto mt-3 max-w-4xl text-xl text-gray-300 sm:mt-5 sm:text-2xl">
              If your product is subscription based let your users know your pricing and features! This will be customised for your product.
            </p>
          </div>
        </div>

        <div className="mt-16 bg-white pb-12 lg:mt-20 lg:pb-20">
          <div className="relative z-0">
            <div className="absolute inset-0 h-5/6 bg-gray-900 lg:h-2/3" />
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="relative lg:grid lg:grid-cols-7">
                <div className="mx-auto max-w-md lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3 lg:mx-0 lg:max-w-none">
                  <div className="flex h-full flex-col overflow-hidden rounded-lg shadow-lg lg:rounded-none lg:rounded-l-lg">
                    <div className="flex flex-1 flex-col">
                      <div className="bg-white px-6 py-10">
                        <div>
                          <h3 className="text-center text-2xl font-medium text-gray-900" id="tier-hobby">
                            Tier 1
                          </h3>
                          <div className="mt-4 flex items-center justify-center">
                            <span className="flex items-start px-3 text-6xl tracking-tight text-gray-900">
                              <span className="mt-2 mr-2 text-4xl font-medium tracking-tight">£</span>
                              <span className="font-bold">XX</span>
                            </span>
                            <span className="text-xl font-medium text-gray-500">/month</span>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between border-t-2 border-gray-100 bg-gray-50 p-6 sm:p-10 lg:p-6 xl:p-10">
                        <ul role="list" className="space-y-4">
                          {hobbyFeatures.map((feature) => (
                            <li key={feature} className="flex items-start">
                              <div className="flex-shrink-0">
                                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                              </div>
                              <p className="ml-3 text-base font-medium text-gray-500">{feature}</p>
                            </li>
                          ))}
                        </ul>
                        <div className="mt-8">
                          <div className="rounded-lg shadow-md">
                            <a
                              href="#"
                              className="block w-full rounded-lg border border-transparent bg-white px-6 py-3 text-center text-base font-medium text-fuchsia-600 hover:bg-gray-50"
                              aria-describedby="tier-hobby"
                            >
                              Start Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mx-auto mt-10 max-w-lg lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4 lg:mx-0 lg:mt-0 lg:max-w-none">
                  <div className="relative z-10 rounded-lg shadow-xl">
                    <div
                      className="pointer-events-none absolute inset-0 rounded-lg border-2 border-fuchsia-600"
                      aria-hidden="true"
                    />
                    <div className="absolute inset-x-0 top-0 translate-y-px transform">
                      <div className="flex -translate-y-1/2 transform justify-center">
                        <span className="inline-flex rounded-full bg-fuchsia-600 px-4 py-1 text-base font-semibold text-white">
                          Most popular
                        </span>
                      </div>
                    </div>
                    <div className="rounded-t-lg bg-white px-6 pt-12 pb-10">
                      <div>
                        <h3
                          className="text-center text-3xl font-semibold tracking-tight text-gray-900 sm:-mx-6"
                          id="tier-growth"
                        >
                          Tier 2
                        </h3>
                        <div className="mt-4 flex items-center justify-center">
                          <span className="flex items-start px-3 text-6xl tracking-tight text-gray-900 sm:text-6xl">
                            <span className="mt-2 mr-2 text-4xl font-medium tracking-tight">£</span>
                            <span className="font-bold">XX</span>
                          </span>
                          <span className="text-2xl font-medium text-gray-500">/month</span>
                        </div>
                      </div>
                    </div>
                    <div className="rounded-b-lg border-t-2 border-gray-100 bg-gray-50 px-6 pt-10 pb-8 sm:px-10 sm:py-10">
                      <ul role="list" className="space-y-4">
                        {growthFeatures.map((feature) => (
                          <li key={feature} className="flex items-start">
                            <div className="flex-shrink-0">
                              <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                            </div>
                            <p className="ml-3 text-base font-medium text-gray-500">{feature}</p>
                          </li>
                        ))}
                      </ul>
                      <div className="mt-10">
                        <div className="rounded-lg shadow-md">
                          <a
                            href="#"
                            className="block w-full rounded-lg border border-transparent bg-fuchsia-600 px-6 py-4 text-center text-xl font-medium leading-6 text-white hover:bg-fuchsia-700"
                            aria-describedby="tier-growth"
                          >
                            Start Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mx-auto mt-10 max-w-md lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3 lg:m-0 lg:max-w-none">
                  <div className="flex h-full flex-col overflow-hidden rounded-lg shadow-lg lg:rounded-none lg:rounded-r-lg">
                    <div className="flex flex-1 flex-col">
                      <div className="bg-white px-6 py-10">
                        <div>
                          <h3 className="text-center text-2xl font-medium text-gray-900" id="tier-scale">
                            Tier 3
                          </h3>
                          <div className="mt-4 flex items-center justify-center">
                            <span className="flex items-start px-3 text-6xl tracking-tight text-gray-900">
                              <span className="mt-2 mr-2 text-4xl font-medium tracking-tight">£</span>
                              <span className="font-bold">XX</span>
                            </span>
                            <span className="text-xl font-medium text-gray-500">/month</span>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between border-t-2 border-gray-100 bg-gray-50 p-6 sm:p-10 lg:p-6 xl:p-10">
                        <ul role="list" className="space-y-4">
                          {scaleFeatures.map((feature) => (
                            <li key={feature} className="flex items-start">
                              <div className="flex-shrink-0">
                                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                              </div>
                              <p className="ml-3 text-base font-medium text-gray-500">{feature}</p>
                            </li>
                          ))}
                        </ul>
                        <div className="mt-8">
                          <div className="rounded-lg shadow-md">
                            <a
                              href="#"
                              className="block w-full rounded-lg border border-transparent bg-white px-6 py-3 text-center text-base font-medium text-fuchsia-600 hover:bg-gray-50"
                              aria-describedby="tier-scale"
                            >
                              Start Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="bg-white">

        <div className="mt-16 bg-white pb-12 lg:mt-20 lg:pb-20">
          <div className="relative z-0">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="h-96 rounded-lg border-4 border-dashed border-gray-200 flex">

                <div class="m-auto">
                  <h2 className="text-3xl select-none font-semibold leading-6 text-gray-300 text-center">Custom Feature?</h2>
                  <p className="text-1xl select-none font-semibold leading-6 text-gray-300 text-center">We can custom build you a feature and plug it into the site here</p>
                </div>


              </div>

            </div>

          </div>
        </div>
      </div>


      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900">Frequently Asked Questions Example</h2>
          <p className="text-center text-1xl font-bold tracking-tight text-gray-900">These can be customised for your product</p>
          <div className="mt-12">
            <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 md:space-y-0 lg:grid-cols-3">
              {faqs.map((faq) => (
                <div key={faq.id}>
                  <dt className="text-lg font-medium leading-6 text-gray-900">{faq.question}</dt>
                  <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className="bg-white">

        <div className="mt-16 bg-white pb-12 lg:mt-20 lg:pb-20">
          <div className="relative z-0">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="h-96 rounded-lg border-4 border-dashed border-gray-200 flex">

                <div class="m-auto">
                  <h2 className="text-3xl select-none font-semibold leading-6 text-gray-300 text-center">Custom Feature?</h2>
                  <p className="text-1xl select-none font-semibold leading-6 text-gray-300 text-center">We can custom build you a feature and plug it into the site here</p>
                </div>


              </div>

            </div>

          </div>
        </div>
      </div>
      <div className="bg-fuchsia-700">
        <div className="mx-auto max-w-2xl py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            <span className="block">One Last Call to Action!</span>
            <span className="block">Your user has read your website - now get them to act!</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-fuchsia-200">
            A call to action can be asking a user to sign up for a mailing list, register for your app, or book a meeting.
          </p>
          <a
            href="#"
            className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-fuchsia-600 hover:bg-fuchsia-50 sm:w-auto"
          >
            Call to Action!
          </a>
        </div>
      </div>

      <Footer></Footer>
    </>

  )
}