import { useState } from 'react';
import './App.css';
import Login from './components/login';
import { Routes, Route } from "react-router-dom";
import Register from './components/register';
import DemoHome from './components/demo-home';
import LandingPage from './components/landing-page';
import Profile from './components/profile';
import GotgLayout from './components/gotg-layout';
import PerformityLayout from './components/performity-layout';
import Login1 from './components/login-1';
import PerformityHome from './components/performity-home';
import PfLayout from './components/pf-layout';
import Login3 from './components/login-3';
import Login2 from './components/login-2';
import PawFloorHome from './components/paw-floor-home';
import GotgHome from './components/gotg-home';
import PerformitySales from './components/performity-sales';
import PerformityProperties from './components/performity-properties';
import PawFloorProduct from './components/paw-floor-product';
import PawFloorSettings from './components/paw-floor-settings';

function App() {

  return (
    <Routes>
      <Route path="/" element={<DemoHome />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/landing" element={<LandingPage />} />


      <Route path="/performity-login" element={<Login1 />} />
      <Route path="/performity" element={<PerformityLayout />}>
        <Route index element={<PerformityHome />} />
        <Route path="*" element={<PerformityHome />} />
        <Route path="/performity/sales" element={<PerformitySales />} />
        <Route path="/performity/properties" element={<PerformityProperties />} />
      </Route>

      <Route path="/paw-floor-login" element={<Login2 />} />
      <Route path="/paw-floor" element={<PfLayout />}>
        <Route index element={<PawFloorHome />} />
        <Route path="*" element={<PawFloorHome />} />
        <Route path="/paw-floor/product" element={<PawFloorProduct />} />
        <Route path="/paw-floor/settings" element={<PawFloorSettings />} />
      </Route>

      <Route path="/give-on-the-go" element={<GotgLayout />}>
        <Route index element={<Login3 />} />
        <Route path="*" element={<GotgHome />} />        
      </Route>
    </Routes>
  )
}

export default App;

