import Header from './header';
import Footer from './footer';
import { Outlet } from "react-router-dom";
import GiveOnTheGoHeader from './gotg-header';

export default function GotgLayout() {

    return (
        <div className="min-h-screen max-h-screen flex flex-col bg-gray-50 p-14">
            <main className="mx-auto min-h-full flex-1 overflow-auto bg-gray-50 border-4 border-gray-800 rounded-xl w-[25rem]">
                {/* <GiveOnTheGoHeader></GiveOnTheGoHeader> */}
                <Outlet></Outlet>
            </main>
        </div>
    )
}